import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Content, Row, Col, Bound } from "../components/layouts"
import SEO from "../components/seo"
import PageIntro from "../components/pageIntro"
import MenuReset from "../components/MenuReset"

import daveSrc from "../images/Dr-David-Abagia.jpg"

const AboutPage = () => (
  <Layout solidHeader>
    <SEO title="About" />
    <MenuReset />

    <PageIntro>
      <h2>Welcome to Fully Activated</h2>
    </PageIntro>

    <Content>
      <Row>
        <Col>
          <Bound>
            <p>
              Fully Activated is a health care centre that offers Osteopathy and
              Applied Kinesiology services as well as top of the range
              supplements and herbal products.
            </p>

            <p>
              At Fully Activated our mission is simple: Pain free, educate and
              improve quality of life. We strive to achieve this because
              everyone deserves a life where they should not be in pain. We have
              a strong passion in education, and we want you to understand what
              is happening in your body. We believe that education is the key
              that unlocks the door to freedom. Together, pain relief and
              education provide you with a better quality of life.
            </p>
            <p>
              We love getting people back to their best. We treat a variety of
              conditions such as: migraines/headaches, sports injuries, back
              pain, neck pain, muscle and joint pain and we also help manage
              chronic pain.
            </p>

            <p>
              <strong>Our Approach</strong>
            </p>

            <p>
              Everyone who is in pain will present differently to the next. At
              Fully Activated, we understand this and appreciate that you do not
              need a formula, you need care that is tailored to you. To achieve
              this, we offer a holistic approach to wellbeing that consists of
              Osteopathic and Applied Kinesiology services. This approach allows
              us to incorporate more than just a physical aspect of your injury
              but also physiological, neurological, dietary and exercise
              components. We want you to get back to doing the things you love.
              Our holistic approach to health also allows us to treat a wide
              variety of people including: sports professionals, kids, newborns,
              elderly and anyone in-between.
            </p>

            <p>
              We are dedicated to improving client health and achieving a better
              quality of life for all. Your health is our number one priority!
            </p>

            <h3>Meet Dave</h3>

            <p className="highlight">
              <strong>Dr. David Abagia (Osteopath)</strong>
              <br />
              <br />
              <strong>Bachelor of Applied Science (Osteopathy)</strong>
              <br />
              RMIT University (2015)
              <br />
              <br />
              <strong>Bachelor of Health Science</strong>
              <br /> RMIT University (2015)
              <br />
              <br />
              <strong>Certified Applied Kinesiology</strong>
              <br /> International College of Applied Kinesiology (2014)
            </p>

            <p>
              I am Dr. David Abagia (Osteopath). I currently hold a Bachelor of
              Applied Science (Osteopathy), a Bachelor of Health Science and a
              Bachelor of Applied Science (Complementary Medicine) from RMIT
              University. I am also one of the few practitioners that achieved
              my Applied Kinesiology certification prior to completing my
              degree.
            </p>
            <p>
              I have a strong passion for helping others to improve their health
              and achieve lifelong wellbeing.
            </p>
            <p>
              This passion, combined with my training and acquired skills, is
              what drives me to provide relevant, meaningful treatment to my
              clients. My purpose is to assist, challenge and inspire my clients
              to improve their health and achieve the lifestyle they desire
              without the hindrance of pain or illness.
            </p>

            <p>
              Out of the office I am a big sports fan with a special interest in
              soccer. I also share a passion for board games and have even
              created some of my own.
            </p>

            <p>
              <Link to="/osteopathy/" title="Osteopathy">
                What is osteopathy?
              </Link>
              <br />
              <Link to="/applied-kinesiology/" title="Applied Kinesiology">
                What is Applied Kinesiology?
              </Link>
            </p>
          </Bound>
        </Col>
        <Col>
          <img src={daveSrc} style={{ width: "75%" }} alt="" />
        </Col>
      </Row>
    </Content>
  </Layout>
)

export default AboutPage
